import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CharacterSceneGame = () => {
    const [characters, setCharacters] = useState([]);
    const [scenes, setScenes] = useState([]);
    const [selectedCharacter, setSelectedCharacter] = useState('');
    const [selectedScene, setSelectedScene] = useState('');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';

    useEffect(() => {
        const fetchCharacters = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/image-files-character`);
                setCharacters(response.data);
            } catch (error) {
                console.error('Error fetching character images:', error);
            }
        };

        const fetchScenes = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/image-files-scene`);
                setScenes(response.data);
            } catch (error) {
                console.error('Error fetching scene images:', error);
            }
        };

        fetchCharacters();
        fetchScenes();
    }, [BACKEND_URL]);

    const handleCharacterChange = (event) => {
        setSelectedCharacter(event.target.value);
    };

    const handleSceneChange = (event) => {
        setSelectedScene(event.target.value);
    };

    return (
        <div>
            <h2>Character and Scene Game</h2>
            <table className="responsive-table">
                <thead>
                    <tr>
                        <th>Character</th>
                        <th>Scene</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select onChange={handleCharacterChange}>
                                <option value="">Select a Character</option>
                                {characters.map((character, index) => (
                                    <option key={index} value={character}>{character}</option>
                                ))}
                            </select>
                        </td>
                        <td>
                            <select onChange={handleSceneChange}>
                                <option value="">Select a Scene</option>
                                {scenes.map((scene, index) => (
                                    <option key={index} value={scene}>{scene}</option>
                                ))}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>

            {/* Display the selected images */}
            {selectedCharacter && (
                <div>
                  
                    <img src={`${BACKEND_URL}/media/character/${selectedCharacter}`} alt={selectedCharacter} />
                    <img src={`${BACKEND_URL}/media/scene/${selectedScene}`} alt={selectedScene} />
                    </div>
            )}
        
        </div>
    );
};

export default CharacterSceneGame;
