import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const MediaPlayer = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState('');
  const audioPlayer = useRef(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5001';

  useEffect(() => {
    // Fetch the list of media files from the server
    const fetchMediaFiles = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/api/media-files`);
        setMediaFiles(response.data);
      } catch (error) {
        console.error('Error fetching media files:', error);
      }
    };

    fetchMediaFiles();
  }, [BACKEND_URL]);

  const playFile = (file) => {
    if (audioPlayer.current) {
      audioPlayer.current.pause(); // Pause the current audio
      audioPlayer.current.currentTime = 0; // Reset the current time
      audioPlayer.current.src = `${BACKEND_URL}/media/${file}`; // Update the source to the new API endpoint
      audioPlayer.current.load(); // Load the new audio file
      audioPlayer.current.play(); // Play the new audio file
      setCurrentFile(file); // Update the current file state
    }
  };

  return (
    <div>
      <h2>Now Playing: {currentFile}</h2>
      <audio ref={audioPlayer} controls>
        {currentFile && (
          <source src={`${BACKEND_URL}/media/${currentFile}`} type="audio/mpeg" />
        )}
        Your browser does not support the audio element.
      </audio>
      <ul>
        {mediaFiles.map((file, index) => (
          <li key={index} onClick={() => playFile(file)}>
            {file}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MediaPlayer;
