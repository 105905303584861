import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MediaPlayer from './components/MediaPlayer';
import CharacterSceneGame from './components/CharacterSceneGame';
import './App.css'; // Import the CSS file for styling

const App = () => {
    return (
        <Router>
            <div className="app-container">
                <header className="header">
                    <img src="/images/Logo2k.png" alt="Logo" className="logo" />
                    <h1>Just For Fun</h1>
                </header>
                <nav>
                    <ul>
                        <li><Link to="/media-player">Media Player</Link></li>
                        <li><Link to="/character-scene-game">Character/Scene Game</Link></li>
                    </ul>
                </nav>

                <Routes>
                    <Route path="/media-player" element={<MediaPlayer />} />
                    <Route path="/character-scene-game" element={<CharacterSceneGame />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
